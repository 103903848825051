import React, {useState} from 'react'
import { FaEnvelope } from 'react-icons/fa'
import {useDispatch} from "react-redux"
import "./resetPassword.scss"
import SRModal from "../../components/modal/modal"
import {remindPassword} from "../../redux/api/apiAction"

import PropTypes from 'prop-types'
import GroupTabs from "../group/tabs/groupTabs";
import {remindPasswordCoordinator} from "../../redux/group/groupAction";

const ResetPassword = ({isCoordinator}) => {
    const dispatch = useDispatch()
    const [success, setSuccess] = useState(false)
    const [error, setError]     = useState(false)
    const [email, setEmail]     = useState("")
    const [validationEmail, setValidationEmail] = useState("")

    const onChange = e => {
        const {value} = e.target
       setEmail(value)
    }

    const checkValidation = () => {
        if(email === "")
            setValidationEmail("Enter your email")
        else
            setValidationEmail("")

        return email.length > 0
    }

    const onSubmit = e => {
        e.preventDefault()

        if(checkValidation()) {
            if(isCoordinator) {
                dispatch(remindPasswordCoordinator(email))
                    .then(res => {
                        if (res === true) {
                            setError("")
                            setEmail("")
                            setSuccess(true)
                        } else {
                            setError("Error !")
                            setSuccess(false)
                        }
                    })
            } else {
                dispatch(remindPassword(email))
                    .then(res => {
                        if (res === true) {
                            setError("")
                            setEmail("")
                            setSuccess(true)
                        } else {
                            setError("Error !")
                            setSuccess(false)
                        }
                    })
            }
        }
    }

    return (
        <SRModal text={"Lost your password ?"} title={"Receive my password"}>
            <form className={"reset-password"} onSubmit={onSubmit}>
                <div className="form-row">
                    <div className={"col-1"}>
                        <label><FaEnvelope/></label>
                    </div>
                    <div className={"col-11"}>
                        <input type="email" className="form-control" placeholder="Email" name={"email"} value={email} onChange={onChange} required/>
                        {validationEmail.length > 0 ?
                            <div className="invalid-feedback">
                                {validationEmail}
                            </div> : ""}
                    </div>
                </div>

                <div className={"form-row"}>
                    {error ?
                        <div className="error invalid-feedback">
                            Error
                        </div>
                        : ""}

                    {success ?
                        <div className="success valid-feedback">
                            Succeeded
                        </div>
                        : ""}
                </div>

                <div className={"form-footer"}>
                    <button type={"submit"} className={"btn btn-info"}>Send</button>
                </div>
            </form>
        </SRModal>
    )
}

ResetPassword.propTypes = {
    isCoordinator: PropTypes.bool,
}

ResetPassword.defaultProps = {
    isCoordinator: false,
}

export default ResetPassword
